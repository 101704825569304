.dictionary__modes {
  border: 1px solid #d6d8db;
  color: #383d41;
  background-color: #e8eaef;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ColumnLegend td,
.ColumnLegend th {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ColumnLegend>tbody>tr>th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background-color: white;
}

.ColumnLegend>tbody>tr,
.ColumnLegend>tbody>tr>td{
  transition: 150ms ;
}

.ColumnLegend>tbody>tr>td{
  border-left: red;
  border-right: red;
}

.ColumnLegend>tbody>tr:hover{
  background-color: rgba(90, 177, 228, 0.3);
}

.ColumnLegend>tbody>tr>td:hover{
  border: 0.5px solid rgb(0, 174, 255);
}