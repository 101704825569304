html{
  padding: 0;
  margin: 0;
}

.map-options {
  background-color: #b0e8ef;
  min-height: 40px;
  max-height: 50px;
}
