.CompareTable td,
.CompareTable th {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DropDownElementButton {
  margin: 20px;
}
