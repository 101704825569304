.super-treeview * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.table-content-wrapper {
  overflow: scroll;
  max-height: 400px;
}
.super-treeview {
  font-size: 100%;
}
.super-treeview > div > .super-treeview-no-children-transition {
  transition-property: all;
}
.super-treeview > div > .super-treeview-no-children-transition-enter {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.super-treeview
  > div
  > .super-treeview-no-children-transition-enter.super-treeview-no-children-transition-enter-active {
  opacity: 1;
  height: 0;
}
.super-treeview > div > .super-treeview-no-children {
  overflow: hidden;
}
.super-treeview
  > div
  > .super-treeview-no-children
  > .super-treeview-no-children-content {
  margin: 2px 0;
}
.super-treeview > div > .super-treeview-node {
  overflow: hidden;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-children-container {
  margin-left: 8px;
  padding-left: 20px;
  border-left: 1px solid #000;
  max-height: 250px;
  overflow: scroll;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-children-container
  > .super-treeview-loading {
  padding: 0 3px;
  margin: 2px 0;
}
.super-treeview > div > .super-treeview-node > .super-treeview-node-content {
  padding: 0 3px;
  margin: 2px 0;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > label {
  vertical-align: top;
  text-overflow: ellipsis;
  width: calc(100% - 55px);
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin: 0;
  font-weight: normal;
  font-size: 100%;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > input[type='checkbox'] {
  float: left;
  margin: 4px 5px 0 0;
  line-height: normal;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > .super-treeview-triangle-btn {
  float: left;
  margin-right: 5px;
  cursor: pointer;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > .super-treeview-triangle-btn-none {
  cursor: none;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > .super-treeview-triangle-btn-right {
  width: 8px;
  height: 10px;
  margin-top: 2px;
  margin-left: 2px;
  border-top: 5px solid transparent;
  border-left: 6px solid #000;
  border-bottom: 5px solid transparent;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > .super-treeview-triangle-btn-down {
  width: 10px;
  height: 8px;
  margin-top: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
}
.super-treeview
  > div
  > .super-treeview-node
  > .super-treeview-node-content
  > .delete-btn {
  float: right;
  cursor: pointer;
}
.super-treeview > div > .super-treeview-node-transition {
  transition-property: all;
}
.super-treeview > div > .super-treeview-node-transition-enter {
  opacity: 0;
}
.super-treeview
  > div
  > .super-treeview-node-transition-enter.super-treeview-node-transition-enter-active {
  opacity: 1;
}
.super-treeview > div > .super-treeview-node-transition-exit {
  opacity: 1;
}
.super-treeview
  > div
  > .super-treeview-node-transition-exit.super-treeview-node-transition-exit-active {
  opacity: 0;
  background-color: red;
}
