.loading-spiner {
  position: relative;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-right: -50px;
}

.leaflet-marker-icon {
  width: 32px;
  height: 37px;
}
