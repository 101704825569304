.adaptiveDateRange{
    margin: auto
}

@media (max-width:360px){
    .rdrMonth{
        width: 100%;
    }
}

